import React, { useRef } from "react";
import Box from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import Button from "@onnit-js/ui/components/button/Button";
import { CartProductConfig } from "@onnit-js/ui/@types/interfaces/CartProductConfig";
import SelectField from "@onnit-js/ui/components/form/selectfield/SelectField";
import styled from "styled-components";
import PriceLabel, { PriceThemeEnum } from "../../cart/total/PriceLabel";
import ProductImage from "../../cart/product/ProductImage";
import DiscountBubble from "../../cart/product/DiscountBubble";
import CartUpsell, { UpsellProduct } from "../../../../interfaces/cart/CartUpsell";
import { useAppDispatch, useAppSelector } from "../../../../configureStore";
import { setAppMessages, setIsLoading } from "../../../../slices/appSlice";
import { addProduct } from "../../../../slices/cartSlice";
import PaymentMethodInstrumentEnum from "../../../../enums/payment/PaymentMethodInstrumentEnum";
import CartMessageLevelEnum from "../../../../enums/CartMessageLevelEnum";
import PageNameEnum from "../../../../enums/PageNameEnum";
import NumberUtil from "../../../../utils/NumberUtil";

interface Props {
    upsell: CartUpsell;
}

const Container = styled(Box)`
    &:last-child {
        border: none !important;
    }
`;

const PreUpsellItem: React.FC<React.PropsWithChildren<Props>> = ({ upsell }) => {
    const dispatch = useAppDispatch();
    const cart = useAppSelector((state) => state.cart);
    const payment = useAppSelector((state) => state.payment);
    const selectRef = useRef<HTMLSelectElement | undefined>();
    const allPricesAreEqual = upsell.products?.every((prod) => prod.price === upsell.products[0].price);
    const isMulti = upsell.products.length > 1;
    const product = upsell.products[0];

    const addUpsell = async (product: UpsellProduct): Promise<void> => {
        if (!cart) {
            throw new Error("Failed to handle add upsell to cart because cart is null.");
        }

        dispatch(setIsLoading(true));

        const config: CartProductConfig = product.cartProductConfig ?? {
            product_id: product.id,
            quantity: 1,
            subscription_interval: null,
            is_upsell: true,
            product_stock_priority_token: null,
        };

        try {
            const newCart = await dispatch(addProduct(config, cart.cart_uuid, `Checkout Upsell`));
            console.debug("Successfully added upsell product to cart.");

            // The customer must re-complete the PayPal flow that authorizes a billing agreement so it can be used for subscription rebills.
            if (newCart?.will_create_subscription
                && payment.tokenizedMethodSelected?.instrument === PaymentMethodInstrumentEnum.PAYPAL
                && !payment.do_save
            ) {
                dispatch(setAppMessages([
                    {
                        code: null,
                        message_html: "By opting into this special offer, you’re creating a subscription. Please confirm your payment information then continue through checkout.",
                        level: CartMessageLevelEnum.INFO,
                        pages_visible: [PageNameEnum.PAYMENT],
                    },
                ]));
            }
         } catch (error: any) {
            console.error("Failed to add upsell product to cart.", error);
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const getMultiItemLabel = (product: UpsellProduct) => {
        let label = product.name;
        const basePrice = product.msrp;
        const actualPrice = product.adjustedPrice ?? product.price;

        if (!allPricesAreEqual) {
            const discountRatio = 1 - (actualPrice / basePrice);
            label += ` | ${NumberUtil.formatMoney(product.adjustedPrice ?? product.price)}`;
            label += ` | ${(discountRatio * 100).toFixed()}% OFF`;
        }

        return label;
    };

    const onSelectChange = (productId: string) => {
        const product = upsell.products.find((prod) => prod.id === Number(productId));
        if (product) {
            addUpsell(product).catch(console.error);
        }
        selectRef?.current?.blur();
    };

    return (
        <Container py={3} borderBottom="1px solid" borderColor="trainingBlues.1">
            <Box display="flex">
                <ProductImage imageUrl={product.authorityImageUrl} alt={product.name} />
                <Box flex="1" display="flex" flexDirection="column" alignItems="flex-start" pt={3}>
                    <Box display="flex" mb={1} alignItems="center">
                        <Text
                            display="block"
                            fontWeight="bold"
                            flex="1"
                            pr={1}
                            textDecoration="none"
                            dangerouslySetInnerHTML={{ __html: upsell.product_group_name }}
                        />
                    </Box>
                    {allPricesAreEqual && (
                        <Box my={1} width={1}>
                            <PriceLabel
                                basePrice={product.msrp}
                                actualPrice={product.adjustedPrice ?? product.price}
                                theme={PriceThemeEnum.DARK}
                                basePriceColor="grays.5"
                            />
                            <Box display="flex" justifyContent="space-between" mt={1}>
                                <DiscountBubble
                                    basePrice={product.msrp}
                                    actualPrice={product.adjustedPrice ?? product.price}
                                />
                                {!isMulti && <Button size="small" onClick={() => addUpsell(product)}>Add</Button>}
                            </Box>
                        </Box>
                    )}
                    {isMulti && (
                        <SelectField
                            bg="white"
                            aria-label="Choose product"
                            ref={selectRef}
                            value=""
                            onChange={(event) => onSelectChange(event.target.value)}
                        >
                            <option key="__00" value="">-- Select --</option>
                            {upsell.products.map((product: UpsellProduct) => (
                                <option key={product.id} value={product.id}>
                                    {getMultiItemLabel(product)}
                                </option>
                            ))}
                        </SelectField>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default PreUpsellItem;
