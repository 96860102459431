import React from "react";
import Text from "@onnit-js/ui/components/text/Text";
import Box from "@onnit-js/ui/components/box/Box";
import NumberUtil from "../../../../utils/NumberUtil";
import CartTotalLabelEnum from "../../../../enums/CartTotalLabelEnum";

interface Props {
    label: CartTotalLabelEnum;
    value: number | null;
    currency?: string | null;
}

const formatValue = (value: number | null, doShowFree: boolean): string => {
    // If it hasn't been calculated yet.
    // Only shipping and tax totals can be null.
    if (value === null) {
        return "-";
    }

    // Shouldn't ever be less than 0, but just for sanity...
    if (value <= 0.0 && doShowFree) {
        return "FREE";
    }

    return NumberUtil.formatMoney(value);
};

export default function CartTotal(props: Props) {
    const doEmphasize = [CartTotalLabelEnum.DISCOUNT, CartTotalLabelEnum.GRAND].includes(props.label);
    let formattedValue = formatValue(props.value, props.label === CartTotalLabelEnum.SHIPPING);

    // Negate the value for discounts because they are represented as positive numbers.
    if (props.label === CartTotalLabelEnum.DISCOUNT) {
        formattedValue = `-${formattedValue}`;
    }

    return (
        <Box py={2} px={5} display="flex" justifyContent="space-between" role="row">
            <Text fontWeight={doEmphasize ? "bold" : "regular"} role="cell">{props.label}</Text>
            <Text fontWeight={doEmphasize ? "bold" : "regular"} role="cell">
                { props.currency && (
                    <Text textTransform="uppercase" display="inline-block" pr={3} fontWeight="regular" verticalAlign="middle" color="grays.4" fontSize="11px">{props.currency}{" "}</Text>
                )}
                {formattedValue}
            </Text>
        </Box>
    );
}
