import isPropValid from '@emotion/is-prop-valid';
import {StyleSheetManager} from 'styled-components';
import {ThemeInterface} from "./interfaces";
import React, {PropsWithChildren} from "react";
import {ThemeProvider as StyledThemeProvider} from "styled-components";


const ThemeProvider: React.FC<PropsWithChildren<{ theme: ThemeInterface }>> = ({theme, children}) => {
    return (
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <StyledThemeProvider theme={theme}>
                {children}
            </StyledThemeProvider>
        </StyleSheetManager>
    )
}


// This implements the default behavior from styled-components v5
function shouldForwardProp(propName:string, target:any) {
    if (typeof target === "string") {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
}

export default ThemeProvider;