import { createGlobalStyle } from "styled-components";
import { theme } from "styled-tools";

export default createGlobalStyle<{ css?: Function }>`
    a, a:active{
        color: ${theme("colors.text")};
    }

    .oui-sup{
        font-size: 0.6em;
        vertical-align: super;
        display: inline;
        color: inherit;
    }

    .sr-only {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;        
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
    }    

    /* IE 11 */
    picture{
        display: block;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .hide-ie {
            display: none !important;
        }
    }

    ${(p) => p.css && p.css(p.theme)};                        
`;
