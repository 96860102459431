import { useRef, useEffect, ReactNode } from "react";
import ReactDOM from "react-dom";

interface Props {
    children: ReactNode;
}

function Portal(props: Props) {
    const container = useRef<HTMLDivElement>(document.createElement("div"));

    useEffect(() => {
        document.body.appendChild(container.current);
        return () => {
            if (container.current && container.current.parentNode) {
                container.current.parentNode.removeChild(container.current);
            }
        };
    }, []);

    return ReactDOM.createPortal(props.children, container.current);
}

export default Portal;
