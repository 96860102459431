import { useTheme } from "styled-components";
import {ThemeInterface} from "../components/themes/interfaces";
import { useMedia } from "@onnit-js/ui/components/media";

function useResponsiveProp(prop: string | number | any[] | undefined) {
    if (!Array.isArray(prop)) {
        return prop;
    }
    const theme = useTheme();
    const breakpoints = (theme as ThemeInterface).breakpoints;
    const mediaQueries = prop.reduce((acc, next, index) => {
        acc[index] = `(min-width: ${breakpoints[index]})`
        return acc;
    }, {});
    const matches = useMedia({ queries: mediaQueries });
    let value = prop[0];
    for (const entry of Object.entries(matches).reverse()){
        if(entry[1]){
            value = prop[Number(entry[0])];
            break;
        }
    }
    return value;
}

export default useResponsiveProp;
