import ProductData from "@onnit-js/ui/@types/interfaces/google-tag-manager/ProductData";

// See: https://developers.google.com/tag-manager/enhanced-ecommerce
// Google Tag Manager works by listening for values exposed on the global dataLayer Window object.

export enum StepNumberEnum {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
    SIX = 6,
    SEVEN = 7,
}

export enum EnhancedEcommerceEventNameEnum {
    PRODUCT_IMPRESSION = "productImpression",
    CART_VIEWED = "cartViewed",
    COUPON_ENTERED = "couponEntered",
    COUPON_APPLIED = "couponApplied",
    COUPON_DENIED = "couponDenied",
    COUPON_REMOVED = "couponRemoved",
    PRODUCT_ADD = "addToCart",
    PRODUCT_REMOVE = "removeFromCart",
    CHECKOUT_STEP = "checkout",
    CHECKOUT_STEP_OPTION = "checkoutOption",
    PURCHASE = "purchase",
    SURVEY_COMPLETED = "surveyCompleted"
}

interface CheckoutActionField {
    step?: StepNumberEnum;
    option?: string;
    list?: string;
    quantitySum?: number;
}

interface CheckoutStepActionField extends CheckoutActionField {
    affiliation?: string | null; // Affiliate tracking code
    revenue?: number; // Grand total
    value?: number; // Grand total for GA4; NOTE: prior to GA4, was MSRP Total
    tax?: number;
    shipping?: number;
    discountAmount?: number; // Discount total
    coupon?: string;
    shippingMethod: string;
    shipping_tier: string; // GA4
}

interface PurchaseActionField {
    // UA parameters
    id: number; // Order ID
    revenue?: number; // Grand total

    // GA4 parameters
    transaction_id: number; // Order ID
    coupon?: string;
    tax?: number;
    shipping?: number;
    value?: number; // Grand Total
    affiliation: string | null; // Affiliate tracking code

    // Used by other
    discountAmount: number; // Discount total
    paymentMethod: string | null;
    customerGroup: string | null;
    isCustomerFirstOrder: boolean;
    customer: {
        id: number;
        firstName?: string;
        lastName?: string;
    };
    shippingMethod: string;
    quantitySum: number;
    shippingAddress: {
        city: string | null;
        state: string | null;
        countryCode: string | null; // Two-character
        countryName: string | null;
    };
    createdAt: string; // ISO-8601 format.
}

// ------------------------- [ Payloads ] -------------------------

export interface EnhancedEcommercePayload<E> {
    event: EnhancedEcommerceEventNameEnum;
    ecommerce: E; // Yay for generics!
}

export interface UserSessionPayload {
    customerID?: number | null;
    email?: string | null;
    emailHash?: string | null;
    firstName?: string | null;
    lastName?: string | null;

    [key: string]: unknown;
}

export interface ProductImpressionPayload {
    impressions: ProductData[]; // UA
    items: ProductData[]; // GA4
}

export interface CartViewedPayload {
    currencyCode: "USD"; // UA
    currency: "USD"; // GA4
    email: string | null;
    emailHash: string | null;
    cart_view: {
        products: ProductData[]; // All products in the cart; UA
        items: ProductData[]; // All products in the cart; GA4
    };
}

export interface CouponActionPayload {
    coupon: string;
}

export interface ProductsAddedPayload {
    currencyCode: "USD"; // UA
    currency: "USD"; // GA4
    email: string | null;
    emailHash: string | null;
    add: {
        actionField: CheckoutActionField;
        products: ProductData[]; // Products added to the cart; UA
        items: ProductData[]; // Products added to the cart; GA4
        allProducts: ProductData[]; // All products in the cart
    };
}

export interface ProductsRemovedPayload {
    currencyCode: "USD"; // UA
    currency: "USD"; // GA4
    email: string | null;
    emailHash: string | null;
    remove: {
        products: ProductData[]; // Products removed from the cart; UA
        items: ProductData[]; // Products removed from the cart; GA4
        allProducts: ProductData[]; // All products in the cart
    };
}

export interface CheckoutData {
    actionField: CheckoutStepActionField; // Yes, you can send a checkout option in this same payload.
    products: ProductData[]; // All products in the cart; UA
    items: ProductData[]; // All products in the cart; GA4
}

export interface CheckoutStepPayload {
    currencyCode: "USD"; // UA
    currency: "USD"; // GA4
    email: string | null;
    emailHash: string | null;
    checkout: CheckoutData;
}

export interface CheckoutStepOptionPayload {
    currencyCode: "USD"; // UA
    currency: "USD"; // GA4
    email: string | null;
    emailHash: string | null;
    checkout_option: CheckoutData;
}

export interface SurveyCompletedPayload {
    survey: {
        actionField: {
            list?: string,
        },
        answer: string;
    },
}

export interface PurchasePayload {
    currencyCode: "USD"; // UA
    currency: "USD"; // GA4
    email: string;
    emailHash: string;
    purchase: {
        actionField: PurchaseActionField;
        products: ProductData[]; // All products in the cart; UA
        items: ProductData[]; // All products in the cart; GA4
    };
}
