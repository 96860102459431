import React from 'react';
import Box, { BoxProps } from '../box/Box';
import { ifProp, prop, theme } from 'styled-tools';
import styled, {css} from "styled-components";

interface Props extends BoxProps {
    count?: number;
    width?: string;
    gap?: number;
}

const MultiColumnText = styled(Box)<Props>`
    @media ${theme('mediaQueries.sm')} {
        column-count: ${prop('count', 2)};
        column-gap: ${p => theme(`space.${p.gap}`, 8)}px;
        ${ifProp(
            'width',
            css<Props>`
                column-width: ${prop('width')};
            `
        )};
    }
`;

export default MultiColumnText;
