import React, { ReactNode, CSSProperties, useMemo } from "react";
import { useLayer } from "react-laag";
import ResizeObserver from "resize-observer-polyfill"; // ResizeObserver is not supported in IE 11. Side-note: This is technically a ponyfill.
import Text from "../text/Text";
import nextId from "react-id-generator";

interface Props {
    text?: string;
    content?: ReactNode;
    style?: CSSProperties;
}

const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({ children, text, content, style }) => {
    const tooltipId = useMemo(() => nextId("oui-tooltip-id-"), []);
    const [isOpen, setOpen] = React.useState(false);
    const { layerProps, triggerProps, renderLayer } = useLayer({
        isOpen,
        placement: "right-center",
        triggerOffset: 4,
        auto: true,
        ResizeObserver: ResizeObserver,
        onOutsideClick: () => setOpen(false),
    });

    return (
        <>
            {isOpen &&
                renderLayer(
                    <div
                        // provide a 'ref' for calculation purposes
                        ref={layerProps.ref}
                        style={{
                            // forward the 'style' we received from 'renderLayer'
                            ...layerProps.style,
                            // provide our own styles
                            background: "hsla(0, 0%, 0%, 0.85)",
                            padding: "0.5em 1em",
                            borderRadius: 4,
                            maxWidth: 240,
                            ...style,
                        }}
                        id={tooltipId}
                        role="tooltip"
                    >
                        {content ? content : <Text color="white">{text}</Text>}
                    </div>
                )}

            {
                <button
                    {...triggerProps}
                    onClick={() => setOpen(!isOpen)}
                    aria-describedby={tooltipId}
                    style={{
                        background: "none",
                        border: "none",
                        padding: 0,
                        cursor: "pointer",
                    }}
                >
                    {children}
                </button>
            }
        </>
    );
};

export default Tooltip;
