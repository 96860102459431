import React, { FC, useEffect, useState } from "react";
import Box from "@onnit-js/ui/components/box/Box";
import useRecommendationsList from "@onnit-js/ui/hooks//amazonPersonalize/useRecommendationsList";
import { ListNameEnum } from "@onnit-js/ui/@types/enums/ListNameEnum";
import GoogleTagManagerUtil from "@onnit-js/ui/utils//google-tag-manager/GoogleTagManagerUtil";
import OverlayProgress from "@onnit-js/ui/components/progress/OverlayProgress";
import Product from "@onnit-js/ui/@types/interfaces/product/Product";
import CartHeading from "../../CartHeading";
import Cart from "../../../../../interfaces/cart/Cart";
import CartRecommendationItem from "./CartRecommendationItem";

interface AmazonPersonalizeContainerProps {
    cart: Cart;
    canAddProducts: boolean;
}

const AmazonPersonalizeContainer: FC<React.PropsWithChildren<AmazonPersonalizeContainerProps>> = ({ cart, canAddProducts }) => {
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
    const {
        isLoading,
        paginatedData,
    } = useRecommendationsList({ pageType: "CART", perPage: 10 });

    useEffect(() => {
        const cartProductIds = new Set(cart.products.map((cartProduct) => cartProduct.product.id));
        const personalizeProducts = paginatedData?.data.filter((product) => !cartProductIds.has(product.product_id) && product.in_stock).slice(0, 2) ?? [];
        setFilteredProducts(personalizeProducts);
    }, [cart.products, paginatedData]);
    if (!canAddProducts || filteredProducts.length <= 0) {
        return null;
    }
    return (
        <>
            {isLoading && <OverlayProgress />}

            <Box my={2}>
                <CartHeading mb={4}>
                    Your recommendation{filteredProducts.length > 1 ? "s" : ""}
                </CartHeading>

                <Box display={filteredProducts.length === 1 ? "block" : "flex"} justifyContent="space-between">
                    {filteredProducts.map((item, index) => (
                        <CartRecommendationItem
                            key={item.product_id}
                            item={item}
                            onClick={(event) => GoogleTagManagerUtil.productClick(
                                event, item, ListNameEnum.RECOMMENDATIONS, index,
                            )}
                        />
                    ))}
                </Box>
            </Box>
        </>
    );
};

export default AmazonPersonalizeContainer;
