import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import Box, { BoxProps } from "../../box/Box";
import { FieldState } from "./FieldContext";
import { ThemeInterface } from "../../themes/interfaces";

type Props = {
    fieldState: FieldState;
    children?: ReactNode;
    bg?: string;
};

interface BorderProps extends BoxProps, FieldState {
    theme?: ThemeInterface;
}

const themeFieldColor = (p: BorderProps) => {
    return p.currentState && p.theme
        ? p.theme.component.formfield[p.currentState].borderColor
        : "gray";
};

const StyledBorder: React.FunctionComponent<React.PropsWithChildren<BorderProps>> = styled(Box)<BorderProps>`
    box-sizing: border-box;
    position: relative;
    /* overflow: hidden; */
    border-width: 2px;
    border-radius: 4px;
    border-style: solid;
    border-color: ${themeFieldColor};

    ${p => {
        if (p.disabled || p.readOnly) {
            return css`
                background-color: ${themeFieldColor};
                pointer-events: none;
            `;
        } else {
            return "";
        }
    }};
`;

function FieldBorder({ fieldState, children, ...rest }: Props) {
    return (
        <StyledBorder {...fieldState} {...rest}>
            {children}
        </StyledBorder>
    );
}

export default FieldBorder;
