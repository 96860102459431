import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import Icon from "@onnit-js/ui/components/icon/Icon";
import ProgressBar from "@onnit-js/ui/components/progress/ProgressBar";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

interface ProgressButtonProps {
    isOpen: boolean;
    label: string;
    percentComplete: number;
    hasGiftInCart: boolean;
    selectionRequired: boolean;
    showIcon?: boolean;
    doAnimate?: boolean;
}

const ProgressButton = ({
    isOpen,
    label,
    percentComplete,
    hasGiftInCart,
    selectionRequired,
    showIcon = true,
    doAnimate = true,
}: ProgressButtonProps) => {
    let isFinalState = false;

    if (percentComplete === 100) {
        if (selectionRequired) {
            if (hasGiftInCart) {
                isFinalState = true;
            }
        } else {
            isFinalState = true;
        }
    }

    const bgColor = isFinalState ? "sunwashGold" : "sunwashGolds.1";

    return (
        <Box
            position="relative"
            borderRadius="40px"
            border="1px solid"
            borderColor="sunwashGold"
            minWidth="112px"
            overflow="hidden"
            pt="8px"
            pb="6px"
            px={3}
        >
            {percentComplete === 100 ? (
                <Box
                    position="absolute"
                    zIndex={0}
                    top={0}
                    left={0}
                    bg={bgColor}
                    height="100%"
                    width="100%"
                >
                    &nbsp;
                </Box>
            ) : (
                <ProgressBar
                    position="absolute"
                    zIndex={0}
                    top={0}
                    left={0}
                    barBgColor="white"
                    barColor={bgColor}
                    percent={percentComplete}
                    height="100%"
                    animateOnMount={doAnimate}
                />
            )}
            <Box display="flex" position="relative" zIndex={1}>
                <Text as="p" flex="1" textAlign="center" fontSize={0} fontWeight="bold" mr={1}>
                    {label}
                </Text>

                {showIcon && (
                    <Icon
                        icon={isOpen ? FaChevronUp : FaChevronDown}
                        size={12}
                        color="black"
                        style={{ transform: "translateY(-1px)" }}
                    />
                )}

            </Box>
        </Box>
    );
};

export default ProgressButton;
