import React, { FC } from "react";
import styled from "styled-components";
import { switchProp } from "styled-tools";
import themeGet from "@styled-system/theme-get";
import { css } from "styled-components";

/**
 * @see https://uiverse.io/namecho/quiet-panther-93
 */

export enum SwitchSize {
    LARGE,
    SMALL,
}

interface SizeProps {
    size: SwitchSize;
}

interface Props extends SizeProps {
    enabled: boolean;
    onChange: () => void;
}

const styles = {
    toggleShadowOffset: '10px',
    colorInactive: '#ccc',
    colorActive: themeGet('colors.earthGreens.3'),
    transition: '0.2s all ease-in-out',

    buttonWidthLg: '3.5em',
    buttonHeightLg: '2em',
    toggleWidthLg: '3em',
    toggleDiameterLg: '1.5em',
    // Small is 70% of large.
    buttonWidthSm: '2.45em',
    buttonHeightSm: '1.4em',
    toggleWidthSm: '2.1em',
    toggleDiameterSm: '1.05em',
};
const buttonToggleOffsetLg = `calc((${styles.buttonHeightLg} - ${styles.toggleDiameterLg}) / 2)`;
const buttonToggleOffsetSm = `calc((${styles.buttonHeightSm} - ${styles.toggleDiameterSm}) / 2)`;

const StyledSpan = styled.span<SizeProps>`
  display: inline-block;
  background-color: ${styles.colorInactive};
  position: relative;
  transition: ${styles.transition};
  cursor: pointer;
  transform: translateY(2px);

  &:after {
    content: "";
    display: inline-block;
    background-color: #fff;
    position: absolute;
    box-shadow: ${styles.toggleShadowOffset} 0 calc(${styles.toggleShadowOffset} * 4) rgba(0, 0, 0, 0.1);
    transition: ${styles.transition};
  }

  ${switchProp("size", {
    [SwitchSize.LARGE]: css`
      width: ${styles.buttonWidthLg};
      height: ${styles.buttonHeightLg};
      border-radius: calc(${styles.buttonHeightLg} / 2);

      &:after {
        width: ${styles.toggleDiameterLg};
        height: ${styles.toggleDiameterLg};
        border-radius: calc(${styles.toggleDiameterLg} / 2);
        top: ${buttonToggleOffsetLg};
        transform: translateX(${buttonToggleOffsetLg});
      }
    `,
    [SwitchSize.SMALL]: css`
      width: ${styles.buttonWidthSm};
      height: ${styles.buttonHeightSm};
      border-radius: calc(${styles.buttonHeightSm} / 2);

      &:after {
        width: ${styles.toggleDiameterSm};
        height: ${styles.toggleDiameterSm};
        border-radius: calc(${styles.toggleDiameterSm} / 2);
        top: ${buttonToggleOffsetSm};
        transform: translateX(${buttonToggleOffsetSm});
      }
    `
  })}
`;

const StyledCheckbox = styled.input`
  display: none;
`;

const StyledLabel = styled.label<SizeProps>`
  ${StyledCheckbox}:checked + ${StyledSpan} {
    background-color: ${styles.colorActive};
  }

  ${StyledCheckbox}:checked + ${StyledSpan}:after {
    box-shadow: calc(${styles.toggleShadowOffset} * -1) 0 calc(${styles.toggleShadowOffset} * 4) rgba(0, 0, 0, 0.1);

    ${switchProp("size", {
      [SwitchSize.LARGE]: css`
        transform: translateX(calc(${styles.buttonWidthLg} - ${styles.toggleDiameterLg} - ${buttonToggleOffsetLg}));
      `,
      [SwitchSize.SMALL]: css`
        transform: translateX(calc(${styles.buttonWidthSm} - ${styles.toggleDiameterSm} - ${buttonToggleOffsetSm}));
      `,
    })}
  }
`;

const Switch: FC<Props> = ({enabled, size, onChange}) => {
    return (
        <StyledLabel
            size={size}
            tabIndex={0}
            onKeyDown={(event: any) => {
                if (["Enter", "Space"].includes(event.code)) {
                    event.preventDefault();
                    event.stopPropagation();
                    onChange();
                }
            }}
        >
            <StyledCheckbox
                type="checkbox"
                checked={enabled}
                onChange={(event: any) => {
                    event.preventDefault();
                    onChange();
                }}
            />
            <StyledSpan size={size}/>
        </StyledLabel>
    );
};

export default Switch;
