import React from "react";
import styled from "styled-components";
import Text, { TextProps } from "../text/Text";
import { prop } from "styled-tools";

interface GradientProps extends TextProps {
    startColor: string;
    endColor: string;
}

const GradientTextContainer = styled(Text)<GradientProps>`
    background: linear-gradient(90deg, ${prop("startColor", "#000")}, 25%, ${prop("endColor", "#000")});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const GradientText: React.FC<React.PropsWithChildren<GradientProps>> = ({ startColor, endColor, children, ...rest }) => {
    return (
        <GradientTextContainer startColor={startColor} endColor={endColor}>
            <Text as="span" {...rest}>
                {children}
            </Text>
        </GradientTextContainer>
    );
};

export default GradientText;
