import React from "react";
import styled from "styled-components";
import Text, { TextProps } from "./Text";
import * as SS from "@techstack/styled-system";
import { ifProp } from "styled-tools";
import {BoxProps} from "../box/Box";

export const List: React.FC<React.PropsWithChildren<TextProps>> = styled.ul.attrs<BoxProps>(() => {
    return { pl: 5 };
})<TextProps>`
    ${SS.background};
    ${SS.border};
    ${SS.shadow};
    ${SS.color};
    ${SS.flexbox};
    ${SS.grid};
    ${SS.layout};
    ${SS.position};
    ${SS.space};
    ${SS.typography};
    list-style-position: outside;
    li {
        list-style-type: ${ifProp({ as: "ol" }, "decimal", "disc")};
        display: list-item;
    }
`;

export const ListItem: React.FC<React.PropsWithChildren<TextProps>> = (props) => <Text as="li" typeStyle="body" {...props} />;
