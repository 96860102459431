import * as Bowser from "bowser";
import supportedBrowsers from "../config/supportedBrowsers";
import BraintreeApplePayManager from "./braintree/BraintreeApplePayManager";

export default class BrowserSupportService {
    static isSupported(supported: Bowser.Parser.checkTree = supportedBrowsers): boolean {
        const { userAgent } = window.navigator;
        const browser = Bowser.getParser(userAgent);
        const isSupported = browser.satisfies(supported);

        if (browser.isBrowser('ie')) {
            return false;
        }

        // Assume it's supported if support couldn't be determined.
        if (isSupported === undefined) {
            console.debug("BrowserSupportService: Unable to determine browser support according to User-Agent '%s', so assuming supported.", userAgent);
            return true;
        }

        return isSupported;
    }

    static canTouch(): boolean {
        // See: https://stackoverflow.com/a/13470899
        return !!("ontouchstart" in window || navigator.maxTouchPoints);
    }

    static supportsApplePay(): boolean {
        return typeof ApplePaySession !== 'undefined'
            && ApplePaySession?.canMakePayments()
            && ApplePaySession?.supportsVersion(BraintreeApplePayManager.APPLE_PAY_VERSION);
    }
}
