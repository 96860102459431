import React, { CSSProperties } from "react";
import Box, { BoxProps } from "../box/Box";
import { useTheme } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { IconType } from "react-icons/lib";

export interface IconProps extends BoxProps {
    icon: IconType;
    /** Pulls default size from theme.reactIconBase */
    size?: number;
    /** styled-system theme color string format. i.e "brand.1" or any color value i.e "red" */
    color?: string;
    /** Style for container */
    style?: object;
    /** Style for Icon component */
    iconStyle?: object;
    /** Title for Icon svg */
    title?: string;

    verticalAlign?: string;

    onClick?: (e: any) => void;

    type?: "submit" | "button";

    ariaLabel?: string;

    as?: any;
}

function Icon(props: IconProps, ref: any) {
    const theme = useTheme();

    const {
        icon: Component,
        iconStyle,
        color,
        size,
        style,
        verticalAlign = "middle",
        type = "button",
        ariaLabel,
        title,
        ...rest
    } = props;

    const iconProps = {
        style: iconStyle,
        color: color ? themeGet(`colors.${color}`, color)({theme}) : theme.reactIconBase.color,
        size: size ? size : theme.reactIconBase.size,
        title,
    };

    let styles: CSSProperties = {
        overflow: "hidden",
        verticalAlign,
        ...style,
    };

    let extraProps = {};

    if (props.onClick) {
        styles = {
            ...styles,
            cursor: "pointer",
            background: "none",
            border: "none",
        };
        extraProps = {
            as: "button",
            type: type,
            "aria-label": ariaLabel,
            role: "button",
        };
    }

    return (
        <Box
            as="span"
            ref={ref}
            display="inline-flex"
            justifyContent="center"
            alignItems="center"
            p={0}
            role="img"
            style={styles}
            {...rest}
            {...extraProps}
        >
            <Component {...iconProps} />
        </Box>
    );
}

export default React.forwardRef(Icon);
