import { CSSProperties, HTMLAttributes } from "react";
import styled from "styled-components";
import * as SS from "@techstack/styled-system";

export interface BaseProps extends HTMLAttributes<HTMLDivElement> {}

export interface BoxProps
    extends BaseProps,
        SS.BackgroundProps,
        SS.BorderProps,
        SS.ShadowProps,
        SS.ColorProps,
        SS.FlexboxProps,
        SS.GridProps,
        SS.LayoutProps,
        SS.PositionProps,
        SS.SpaceProps,
        SS.TypographyProps {
    // We have to add this manually to avoid conflicts with HTMLAttributes in types/styled-components - IntrinsicElements
    transform?: string | string[];
    style?: CSSProperties;
    htmlFor?: string;
    color?: any;
    content?: any
}

export const Box = styled.div<BoxProps>`
    box-sizing: border-box;
    ${SS.background};
    ${SS.border};
    ${SS.shadow};
    ${SS.color};
    ${SS.flexbox};
    ${SS.grid};
    ${SS.layout};
    ${SS.position};
    ${SS.space};
    ${SS.typography};
    ${SS.system({
        transform: {
            property: "transform"
        }
    })}
`;

export default Box;
