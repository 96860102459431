import React, {ReactNode, Ref} from "react";
import { FieldState } from "./FieldContext";
import { FieldBorder, HelperText } from "./";
import FloatLabel from "./FloatLabel";
import Box from "../../box/Box";
import * as SS from "@techstack/styled-system";

export interface FieldProps extends SS.SpaceProps, SS.FlexboxProps {
    boxProps?: any;
    children?: ReactNode;
    error?: string | null;
    helperText?: string;
    icon?: ReactNode;
    isValid?: boolean;
    label?: string;
    ref?: Ref<unknown>;
    htmlFor?: string;
    bg?: string;
}

interface Props extends FieldProps {
    fieldState: FieldState;
}

function Field({ fieldState, children, helperText, label, error, icon, boxProps, htmlFor }: Props, ref:any) {
    const { bg = "transparent", ...otherBoxProps } = boxProps;
    const doShrink = fieldState.hasValue || fieldState.focused;

    //because a React.forwardRef component can't have default props.
    const defaultBoxProps = {
        my: 2,
    };

    return (
        <Box ref={ref} {...{ ...defaultBoxProps, ...otherBoxProps }}>
            <FieldBorder fieldState={fieldState} bg={bg}>
                {label && (
                    <FloatLabel htmlFor={htmlFor} doShrink={doShrink}>
                        {label}
                    </FloatLabel>
                )}
                {children}
                {icon && (
                    <Box
                        width="57px"
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            zIndex: 2,
                        }}>
                        {icon}
                    </Box>
                )}
            </FieldBorder>
            {error || helperText ? (
                <HelperText id={`${htmlFor}__helper-text`} fieldState={fieldState}>
                    {error ? error : helperText}
                </HelperText>
            ) : null}
        </Box>
    );
}

export default React.forwardRef(Field);
