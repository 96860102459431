import React from "react";
import Text from "@onnit-js/ui/components/text";
import { DoubleDaggerSymbol } from "@onnit-js/ui/components/text/DaggerSymbol";
import ProductWarningEnum from "../../../../enums/ProductWarningEnum";

interface Props {
    warnings: ProductWarningEnum[];
}

const ProductWarningSymbols = (props: Props) => {
    const warnings = new Set(props.warnings);

    return (
        <Text display="inline" color="grays.4" fontWeight="bold">
            {warnings.has(ProductWarningEnum.PROP_65) && "*"}
            {warnings.has(ProductWarningEnum.VINPOCETINE) && (
                <sup>
                    <DoubleDaggerSymbol />
                </sup>
            )}
        </Text>
    );
};

export default ProductWarningSymbols;
