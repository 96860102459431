import React, { FocusEvent } from "react";
import { hasValue } from "./utils";
import nextId from "react-id-generator";

//This interace extends the HTMLInputElement so we don't have to redefine all the props coming in from props.inputProps
//The addition specific props are coming from the outside world. i.e <TextField isValue={true} />
interface FieldProviderProps extends React.InputHTMLAttributes<HTMLInputElement> {
    error?: string;
    isValid?: boolean;
}

export enum FieldStates {
    INITIAL = "initial",
    VALID = "valid",
    ACTIVE = "active",
    READONLY = "readOnly",
    DISABLED = "disabled",
    ERROR = "error",
}

export interface FieldState {
    focused?: boolean;
    checked?: boolean;
    hasValue?: boolean;
    hasError?: boolean;
    isValid?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    currentState?: FieldStates;
}

export interface IFieldContext {
    htmlId: string;
    fieldState: FieldState;
    onFocus?: (event: FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLDivElement>) => void;
    onBlur: (event: FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLDivElement>) => void;
    blur?: any;
}

let FieldContext = React.createContext({} as IFieldContext);
const { Provider, Consumer } = FieldContext;

class FieldProvider extends React.Component<FieldProviderProps> {
    htmlId = nextId("oui-form-field-id-");

    state = {
        focused: false,
    };

    handleFocus = (evt: any) => {
        if (this.props.readOnly || this.props.disabled) return;
        this.setState({ focused: true });
        this.props.onFocus && this.props.onFocus(evt);
    };

    handleBlur = (evt: any) => {
        this.setState({ focused: false });
        this.props.onBlur && this.props.onBlur(evt);
    };

    blur = () => {
        this.setState({ focused: false });
    };

    render() {
        const { focused } = this.state;
        const { value, checked, error, isValid, disabled, readOnly } = this.props;

        const fieldState: FieldState = {
            checked,
            focused,
            isValid,
            disabled,
            readOnly,
            hasError: Boolean(error),
            hasValue: hasValue(value),
        };

        fieldState.currentState = stateReducer(fieldState);

        return (
            <Provider
                value={{
                    htmlId: this.htmlId,
                    fieldState,
                    onFocus: this.handleFocus,
                    onBlur: this.handleBlur,
                    blur: this.blur,
                }}>
                {this.props.children}
            </Provider>
        );
    }
}

export { FieldProvider, Consumer as FieldConsumer, FieldContext };

export function stateReducer(fieldState: FieldState): FieldStates {
    if (fieldState.hasError) {
        return FieldStates.ERROR;
    }
    if (!fieldState.hasError && fieldState.isValid) {
        return FieldStates.VALID;
    }
    if (fieldState.focused || fieldState.checked) {
        return FieldStates.ACTIVE;
    }
    if (fieldState.readOnly) {
        return FieldStates.READONLY;
    }
    if (fieldState.disabled) {
        return FieldStates.DISABLED;
    }
    return FieldStates.INITIAL;
}
