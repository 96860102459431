import React, { ChangeEvent, Component, FormEvent, MouseEvent } from "react";
import { connect } from "react-redux";
import Text from "@onnit-js/ui/components/text";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { FaChevronRight } from "react-icons/fa";
import ThunkDispatch from "../../../interfaces/ThunkDispatch";
import { applyCoupon } from "../../../slices/cartSlice";
import { CartCouponConfig } from "../../../interfaces/coupon/CartCouponConfig";
import Cart from "../../../interfaces/cart/Cart";
import CouponInput from "./CouponInput";
import { setIsLoading } from "../../../slices/appSlice";
import NumberUtil from "../../../utils/NumberUtil";
import ErrorMessageEnum from "../../../enums/ErrorMessageEnum";

interface Props {
    cart: Cart;
    isInitiallyVisible: boolean;
    // --- [ Redux injected ] ---
    setIsLoading: (isLoading: boolean) => void;
    applyCoupon: (config: CartCouponConfig) => Promise<Cart>;
}

interface State {
    code: string;
    error: string;
    successDiscountTotal: number;
    isVisible: boolean;
}

class CouponInputContainer extends Component<Props, State> {
    static defaultProps = {
        isInitiallyVisible: false,
    };

    state = {
        code: "",
        error: "",
        successDiscountTotal: 0.0,
        isVisible: this.props.isInitiallyVisible,
    };

    private resetStatus() {
        // Clear any previous error or success amount.
        this.setError("");
        this.setSuccessDiscountTotal(0.0);
    }

    // ------------------------- [ State Setters ] -------------------------

    private setCode(code: string): void {
        this.setState({
            code: code.toUpperCase(), // Always uppercase.
        });
    }

    private setError(error: string): void {
        this.setState({
            error,
        });
    }

    private setSuccessDiscountTotal(successDiscountTotal: number): void {
        this.setState({
            successDiscountTotal,
        });
    }

    private setIsVisible(isVisible: boolean): void {
        this.setState({
            isVisible,
        });
    }

    // ------------------------- [ Event Handlers ] -------------------------

    private onShowClick = (event: MouseEvent<HTMLSpanElement>): void => {
        event.preventDefault();
        this.setIsVisible(true);
    };

    private onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setCode(event.target.value);

        this.resetStatus();
    };

    private onSubmit = async (event: FormEvent): Promise<void> => {
        event.preventDefault();

        // Don't do anything if it's empty.
        if (!this.state.code) {
            return;
        }

        this.resetStatus();
        this.props.setIsLoading(true);

        try {
            const cart = await this.props.applyCoupon({
                cart_uuid: this.props.cart.cart_uuid,
                code: this.state.code.trim(),
            });

            this.setCode("");
            this.setSuccessDiscountTotal(cart.totals.discount);
         } catch (error: any) {
            const errorMessage = error?.response?.data?.error_message ?? ErrorMessageEnum.GENERIC;
            this.setError(errorMessage);
        } finally {
            this.props.setIsLoading(false);
        }
    };

    // ------------------------- [ Lifecycle Methods ] -------------------------

    render() {
        const { cart } = this.props;
        const { code, error, isVisible, successDiscountTotal } = this.state;
        const showMessage = isVisible && !error;
        // Multiple coupons can be applied if one or both are gift cards.
        const isCouponApplied = !!cart.coupons.find((coupon) => !coupon.is_gift_card);

        return (
            <>
                {isVisible ? (
                    <CouponInput
                        value={code}
                        error={error}
                        onChange={this.onChange}
                        onSubmit={this.onSubmit}
                    />
                ) : (
                    <Text
                        as="button"
                        color="grays.5"
                        textAlign="center"
                        width={1}
                        p={3}
                        onClick={this.onShowClick}
                    >
                        Apply coupon or gift card <Icon color="grays.4" icon={FaChevronRight} />
                    </Text>
                )}
                {showMessage && successDiscountTotal > 0.0 && (
                    <Text color="sunwashGolds.6" px={3} mt={2}>
                        Great! You will save {NumberUtil.formatMoney(successDiscountTotal)}.
                    </Text>
                )}
                {showMessage && !successDiscountTotal && isCouponApplied && (
                    // Informative text to the user.
                    <Text fontSize={1} color="grays.4" mt={2} px={2}>
                        Only one coupon can be applied per order.
                    </Text>
                )}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
    setIsLoading: (isLoading: boolean) => dispatch(setIsLoading(isLoading)),
    applyCoupon: (config: CartCouponConfig) => dispatch(applyCoupon(config)),
});

export default connect(
    null,
    mapDispatchToProps,
)(CouponInputContainer);
