export function getUrlParameter(name: string) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function isValidEmail(email: string) {
    if (email.trim() === "") return false;
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.trim());
}

export function isValidPhoneNumber(n: string) {
    if (n.trim() === "") return false;
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g.test(n.trim());
}

/**
 * const REG = /^([mpfw][trblxy]?|outline|small|fullwidth|width|color|bg)$/;
   removeProps(REG, props)
 * @param {RegExp} REG
 * @param {object} props
 */
export const removeProps = (REG: RegExp, props: any) => {
    const next: any = {};

    for (let key in props) {
        if (REG.test(key)) {
            continue;
        }
        next[key] = props[key];
    }

    return next;
};

/**
 * Split Box Props
 */
const REG = RegExp(/^([mpfw][trblxy]?|width|height|display|flex|alignItems|justifyContent|bg)$/);

interface INameToValueMap {
    [key: string]: any;
}

export function splitBoxProps(props: INameToValueMap, regEx = REG) {
    let otherProps: INameToValueMap = {};
    let boxProps: INameToValueMap = Object.keys(props).reduce((acc: INameToValueMap, key: string) => {
        if (regEx.test(key)) {
            acc[key] = props[key];
        } else {
            otherProps[key] = props[key];
        }
        return acc;
    }, {});

    return [boxProps, otherProps];
}


/**
 * Generate a random string of characters. Useful for random class names
 */
export function generateString(length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}