import OrderCreatedEvent from "../events/OrderCreatedEvent";
import CartProductsAddedEvent from "../events/CartProductsAddedEvent";
import CartProductRemovedEvent from "../events/CartProductRemovedEvent";
import GoogleTagManagerService from "../../services/analytics/google-tag-manager/GoogleTagManagerService";
import PageInitializedEvent from "../events/PageInitializedEvent";
import ProductImpressionEvent from "../events/ProductImpressionEvent";
import PageSubmittedEvent from "../events/PageSubmittedEvent";
import UserSessionUpdatedEvent from "../events/UserSessionUpdatedEvent";
import CartViewedEvent from "../events/CartViewedEvent";
import CartCouponEnteredEvent from "../events/CartCouponEnteredEvent";
import CartCouponAppliedEvent from "../events/CartCouponAppliedEvent";
import CartCouponDeniedEvent from "../events/CartCouponDeniedEvent";
import CartCouponRemovedEvent from "../events/CartCouponRemovedEvent";
import SurveyCompletedEvent from "../events/SurveyCompletedEvent";

const gtmService = new GoogleTagManagerService();

export const trackUserSession = (event: UserSessionUpdatedEvent) => {
    const { cart } = event;

    gtmService.trackUserSession(cart);
};

export const trackProductImpression = (event: ProductImpressionEvent) => {
    const { products, listName } = event;

    gtmService.trackProductImpression(products, listName);
};

export const trackCartViewed = (event: CartViewedEvent) => {
    const { cart } = event;

    gtmService.trackCartViewed(cart);
};

export const trackCouponEntered = (event: CartCouponEnteredEvent) => {
    gtmService.trackCouponEntered(
        event.code,
    );
};

export const trackCouponApplied = (event: CartCouponAppliedEvent) => {
    gtmService.trackCouponApplied(
        event.code,
    );
};

export const trackCouponDenied = (event: CartCouponDeniedEvent) => {
    gtmService.trackCouponDenied(
        event.code,
    );
};

export const trackCouponRemoved = (event: CartCouponRemovedEvent) => {
    gtmService.trackCouponRemoved(
        event.code,
    );
};

export const trackProductsAdded = (event: CartProductsAddedEvent) => {
    const { cart, cartProductsAdded, listName } = event;

    gtmService.trackProductsAdded(
        cart,
        cartProductsAdded,
        listName,
    );
};

export const trackProductRemoved = (event: CartProductRemovedEvent) => {
    const { cart, cartProductRemoved } = event;

    gtmService.trackProductRemoved(cart, cartProductRemoved);
};

export const trackCheckoutStep = (event: PageInitializedEvent) => {
    const { cart, route } = event;
    const stepNumber = gtmService.getCheckoutStepNumber(route);

    // Since the PageInitializedEvent is emitted on CartPage, which is not a checkout step, we want to skip it.
    if (!stepNumber) {
        console.debug("Google Tag Manager: Skipped tracking checkout step because step number is undefined for the route.", route);
        return;
    }

    gtmService.trackCheckoutStep(cart, stepNumber);
};

export const trackCheckoutOption = (event: PageSubmittedEvent) => {
    const stepNumber = gtmService.getCheckoutStepNumber(event.page);
    const { page, option, cart } = event;
    if (!stepNumber) {
        console.error("Google Tag Manager: Step number is unexpectedly undefined for route '%s'.", event.page);
        return;
    }
    if (!option) {
        console.warn("Google Tag Manager: Skipped tracking checkout option on route '%s' because an option was not defined.", page);
        return;
    }
    if (!cart) {
        console.warn("Google Tag Manager: Skipped tracking checkout option on route '%s' because cart was not defined.", page);
        return;
    }

    gtmService.trackCheckoutStepOption(
        cart,
        stepNumber,
        Array.isArray(option) ? option.join(", ") : option,
    );
};

export const trackPurchase = (event: OrderCreatedEvent) => {
    const { cart, order } = event;

    gtmService.trackPurchase(cart, order);
};

export const trackPasswordSet = () => {
    gtmService.trackPasswordSet();
};

export const trackSurveyCompleted = (event: SurveyCompletedEvent) => {
    const { answer, listName } = event;
    gtmService.trackSurveyCompleted(answer, listName);
};
