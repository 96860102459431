export default class NumberUtil {
    static formatMoney(value: any, withCents = true): string {
        // extra precaution
        const val = Number(value);
        const formatted: string = withCents ? val.toFixed(2) : Math.ceil(val).toString();

        return `$${formatted}`;
    }

    static format(value: number): string {
        return Number(value).toLocaleString("en");
    }
}
