import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import qs from "qs";
import config from "../config";

export default abstract class AbstractClient {
    protected axios: AxiosInstance;

    constructor(axiosClient: AxiosInstance | null = null) {
        this.axios = axiosClient ?? axios.create({
            baseURL: this.getBaseUrl(),
            timeout: this.getTimeout(),
            withCredentials: this.withCredentials(),
        });

        this.axios.interceptors.response.use((response) => response, (error) => {
            if ([401, 403].includes(error?.response?.status)) {
                // The likely cause is that the customer is authenticated to CRE but not the API.
                // Logout the customer to have them reauthenticate so a new API session can be created.
                const currentUri = window.location.href.replace(window.location.origin, "");
                window.location.href = `${config.ALLIT_URL}/cart/logoff.php?next=${encodeURIComponent(currentUri)}`;
            }

            return Promise.reject(error);
        });
    }

    protected abstract getBaseUrl(): string;

    protected getTimeout(): number {
        return 30 * 1000;
    }

    protected withCredentials(): boolean {
        return true;
    }

    protected getUrlEncodedParamSerializer(): AxiosRequestConfig["paramsSerializer"] {
        return ((params) => qs.stringify(params));
    }
}