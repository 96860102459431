import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text/Text";
import { useAppSelector } from "../../../../configureStore";
import CartUpsell from "../../../../interfaces/cart/CartUpsell";
import PreUpsellItem from "./PreUpsellItem";

const PreUpsellList = () => {
    const upsells = useAppSelector((state) => state.upsells?.upsells);

    if (!upsells || upsells?.length === 0) {
        return null;
    }

    return (
        <Box mx={-5}>
            <Box bg="trainingBlues.7" py={2}>
                <Text
                    textTransform="uppercase"
                    fontStyle="italic"
                    textAlign="center"
                    fontWeight="black"
                    color="trainingBlues.0"
                    fontSize={[2, 2, 3]}
                >
                    Limited time offers
                </Text>
            </Box>
            <Box bg="trainingBlues.0" py={4} px={6}>
                {upsells.map((upsell: CartUpsell) => <PreUpsellItem key={upsell.upsell_id} upsell={upsell} />)}
            </Box>
        </Box>
    );
};

export default PreUpsellList;
