import React from "react";
import styled from "styled-components";
import Box from "../../box/Box";
import Text, {TextProps} from "../../text/Text";
import {ThemeInterface} from "../../themes/interfaces";
import {FieldStates} from "./FieldContext";

export {default as FieldBorder} from "./FieldBorder";

const themeFieldColor = (p: { currentState: string; theme: ThemeInterface }) => {
    return p.currentState && p.theme
        ? p.theme.component.formfield[p.currentState].helperTextColor
        : "gray";
};

const StyledText = styled(Text)<TextProps & {currentState: FieldStates}>`
    color: ${themeFieldColor};
    user-select: none;
`;

export function HelperText({fieldState, children, id, pl = 3, pt = 1, ...rest}: any) {
    return (
        <Box pl={pl} pt={pt} {...rest}>
            <StyledText
                id={id}
                fontSize={1}
                currentState={fieldState.currentState}
                role={fieldState.currentState === FieldStates.ERROR ? "alert" : undefined}>
                {children}
            </StyledText>
        </Box>
    );
}