import React, { Component } from "react";
import styled from "styled-components";
import Box from "../../box/Box";
import Text from "../../text/Text";
import { MdAdd, MdRemove } from "react-icons/md";
import { themeGet } from "@styled-system/theme-get";
import Icon from "../../icon/Icon";
import * as SS from "@techstack/styled-system";
import { hasValue } from "../field/utils";
import { add, inRange, isFinite, subtract } from "lodash-es";
import nextId from "react-id-generator";

//NOTE input element is readOnly so use can't type into it which causes API issues.

export interface NumberFieldChangeEvent {
    target: {
        name?: string;
        value: string;
    };
}

export interface Props extends SS.SpaceProps {
    min: number;
    max: number;
    step: number;
    name?: string;
    value?: any;
    disabled?: boolean;
    readOnly?: boolean;
    error?: string;
    helperText?: string;
    label?: string;
    onChange: (event: NumberFieldChangeEvent) => void;
}

const Container = styled(Box)`
    input[type="number"] {
        border: none;
        outline: none;
        text-align: center;
        font-size: ${themeGet("fontSizes.1")}px;
        font-weight: ${themeGet("fontWeights.regular")};
        font-family: ${themeGet("fonts.primary")};
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
`;

const Button = styled.button`
    -webkit-touch-callout: none;
    user-select: none;
    background: ${(p) => themeGet(p.disabled ? "colors.grays.0" : "colors.grays.2")};
    border: none;
    border-radius: 4px;
    height: 30px;
    padding: 0 6px;
    outline: none;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        background: ${themeGet("colors.grays.3")};
    }

    &:hover:not(:disabled) {
        cursor: pointer;
    }
`;

const ScreenReaderOnly = styled.span`
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
`;

/*
    if there is valid value, update the count, else return just the step value.
*/
const updateCount = (fn: Function) => (step: number, value: any): number =>
    hasValue(value) ? fn(parseInt(value, 10), step) : step;
const increment = updateCount(add);
const decrement = updateCount(subtract);

class NumberField extends Component<Props> {
    htmlId = nextId("oui-form-field-id-");

    static defaultProps = {
        step: 1,
        min: -Infinity,
        max: Infinity,
    };

    componentDidMount() {
        const { value, min, max } = this.props;
        //check if intial value is in range.
        if (hasValue(value) && !inRange(value, min, max + 1)) {
            console.error(
                `The provided value (${value}) to NumberField is out of the min(${min}) / max(${max}) bounds`
            );
        }
    }

    /*
        Handle the change event from the input element.
        convert the string value to a number,
        then check if in bounds if min or max is set.
    */
    handleChange = (e: NumberFieldChangeEvent) => {
        const { onChange, min, max } = this.props;
        const numValue = parseInt(e.target.value, 10);

        if (isFinite(numValue)) {
            if (inRange(numValue, min, max + 1)) {
                onChange(e);
            }
        } else {
            //value is empty string useful for clearing the field on user input.
            onChange(e);
        }
    };

    render() {
        const {
            name,
            value,
            onChange,
            step,
            min,
            max,
            disabled,
            error,
            helperText,
            label = "Quantity",
            ...rest
        } = this.props;

        let isDecrementDisabled = isFinite(value) ? parseInt(value, 10) <= min : false;

        let isIncrementDisabled = isFinite(value) ? parseInt(value, 10) >= max : false;

        return (
            <Container minWidth="94px" {...rest}>
                <Button
                    type="button"
                    onClick={() =>
                        this.handleChange({
                            target: {
                                name,
                                value: decrement(step, value).toString(),
                            },
                        })
                    }
                    disabled={isDecrementDisabled}
                    aria-label={`Decrease ${label}`}>
                    <Icon icon={MdRemove} />
                </Button>
                <label>
                    <ScreenReaderOnly>{label}</ScreenReaderOnly>
                    <input
                        id={this.htmlId}
                        tabIndex={-1}
                        type="number"
                        name={name}
                        pattern="\d*"
                        min={min}
                        max={max}
                        disabled={disabled}
                        readOnly={true}
                        value={value}
                        onChange={this.handleChange}
                        style={{ width: 32, height: 32, userSelect: "none" }}
                        aria-describedby={
                            error || helperText ? `${this.htmlId}__helper-text` : undefined
                        }
                    />
                </label>
                <Button
                    type="button"
                    onClick={() =>
                        this.handleChange({
                            target: {
                                name,
                                value: increment(step, value).toString(),
                            },
                        })
                    }
                    disabled={isIncrementDisabled}
                    aria-label={`Increase ${label}`}>
                    <Icon icon={MdAdd} />
                </Button>
                {error || helperText ? (
                    <Text
                        fontSize={1}
                        color={error ? "gameRed" : "grays.4"}
                        mt={1}
                        id={`${this.htmlId}__helper-text`}
                        role="alert">
                        {error ? error : helperText}
                    </Text>
                ) : null}
            </Container>
        );
    }
}

export default NumberField;
