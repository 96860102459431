import React, {FC, ReactElement, ReactNode} from "react";
import Box, {BoxProps} from "../box/Box";
import Text from "./Text";

export interface SectionHeaderProps extends Omit<BoxProps, "title"> {
    icon?: ReactNode;
    title: ReactNode;
    as?: string;
}

const SectionHeader: FC<React.PropsWithChildren<SectionHeaderProps>> = ({
    as = "p",
    icon,
    title,
    children,
    mt = 3,
    mb = 3,
    ...rest
}) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            borderBottom="2px solid"
            borderColor="grays.1"
            py={4}
            mt={mt}
            mb={mb}
            {...rest}
        >
            {icon && <Box mr={2}>{icon}</Box>}
            <Box flex="1" display="flex" justifyContent="space-between" alignItems="center">
                <Text fontWeight="bold" fontSize={3} mb={0} lineHeight={6} as={as}>
                    {title}
                </Text>
                {children}
            </Box>
        </Box>
    );
};

export default SectionHeader;
