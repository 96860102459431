import React from "react";
import Text from "@onnit-js/ui/components/text";
import { BoxProps } from "@onnit-js/ui/components/box/Box";
import Bubble from "../../../shared/oui/bubble/Bubble";

interface Props extends BoxProps {
    basePrice: number;
    actualPrice: number;
}

const DiscountBubble: React.FC<React.PropsWithChildren<Props>> = ({ basePrice, actualPrice, ...otherProps }) => {
    if (basePrice === 0) {
        return null;
    }

    let discountRatio = 1 - (actualPrice / basePrice);

    // Don't show if it's not discounted at all or if it's fully discounted.
    // Sanity: it shouldn't ever be outside the bounds of 0...1
    if (discountRatio <= 0 || discountRatio >= 1) {
        return null;
    }

    if (discountRatio > 0 && discountRatio < 0.01) {
        // Never show 0% off, round up to 1% for display purposes.  This is an edge case for coupons.
        discountRatio = 0.01;
    }

    return (
        <Bubble bg="sunwashGold" height="22px" alignItems="center" {...otherProps}>
            <Text color="black" fontSize="12px">
                {(discountRatio * 100).toFixed()}% OFF
            </Text>
        </Bubble>
    );
};

export default DiscountBubble;
