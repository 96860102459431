import React from "react";
import Field, { FieldProps } from "../field/Field";
import { FieldConsumer, FieldProvider } from "../field/FieldContext";
import {splitBoxProps} from "../../../utils/utils";
import styled from "styled-components";
import { ifProp } from "styled-tools";
import { themeGet } from "@styled-system/theme-get";

type StyledInputProps = {
    hasLabel?: boolean;
    hasIcon?: boolean;
    multiline: boolean;
};

const StyledInput = styled.input.attrs<StyledInputProps>((props: StyledInputProps) => ({
    as: props.multiline ? "textarea" : "input",
}))<StyledInputProps>`
    box-sizing: border-box;
    position: relative;
    color: ${themeGet("colors.text")};
    font-family: ${themeGet("fonts.primary")};
    font-size: ${themeGet("fontSizes.2")}px;
    -webkit-font-smoothing: antialiased;
    letter-spacing: normal;
    border: none;
    background: none;
    line-height: 1;
    padding: ${ifProp("hasLabel", "30px 10px 10px 10px", "13px 10px 12px 10px;")};
    width: ${ifProp("hasIcon", "calc(100% - 57px)", "100%")};
    z-index: 3;
    &:focus {
        outline: none;
    }
`;

export interface TextFieldProps
    extends FieldProps,
        React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    multiline?: boolean;
    rows?: number;
}

function TextField(props: TextFieldProps, ref: any) {
    const [boxProps, otherProps] = splitBoxProps(props);
    const {
        helperText,
        label,
        error,
        icon,
        isValid,
        onBlur,
        onFocus,
        multiline = false,
        placeholder,
        ...inputProps
    } = otherProps;

    return (
        <FieldProvider {...inputProps} isValid={isValid} error={error} onBlur={onBlur} onFocus={onFocus}>
            <FieldConsumer>
                {({ fieldState, onBlur, onFocus, htmlId }) => {
                    return (
                        <Field
                            htmlFor={htmlId}
                            fieldState={fieldState}
                            boxProps={boxProps}
                            helperText={helperText}
                            label={label}
                            error={error}
                            icon={icon}
                        >
                            <StyledInput
                                multiline={multiline}
                                hasLabel={!!label}
                                hasIcon={!!icon}
                                type="text"
                                onBlur={onBlur}
                                onFocus={onFocus}
                                placeholder={placeholder}
                                aria-label={placeholder}
                                aria-describedby={error || helperText ? `${htmlId}__helper-text` : undefined}
                                {...inputProps}
                                id={htmlId}
                                ref={ref}
                            />
                        </Field>
                    );
                }}
            </FieldConsumer>
        </FieldProvider>
    );
}

export default React.forwardRef(TextField);
