import {parse, ParsedQs, stringify} from "qs";
import Product from "../@types/interfaces/product/Product";
import SubscriptionUpdateSourceEnum from "../@types/enums/SubscriptionUpdateSourceEnum";
import config from "../config";

interface SubscriptionCancellationParameters {
    subscription_id: number;
    source: SubscriptionUpdateSourceEnum | null;
}

export default class UrlUtil {
    private static readonly ALLIT_URI_TO_WEBPACK_DEV_URI = new Map([
        ["/cart/recommendations/", "/cart/recommendations"],
        ["/cart/favorites/", "/cart/favorites"],
        ["/cart/subscriptions/", "/cart/subscriptions"],
        ["/cart/payment-methods/", "/cart/payment-methods"],
        ["/cancel-subscription/", "/cancel-subscription.html"],
    ]);

    private static readonly PARAM_SUBSCRIPTION_ID = "subscription_id";

    private static readonly PARAM_SOURCE = "source";

    private static readonly PARAM_PRODUCT_ID = "pid";

    private static readonly PARAM_VARIANT_ID = "variant";

    static parseQuery(query: string): ParsedQs | null {
        try {
            return parse(
                window.decodeURIComponent(query),
                { ignoreQueryPrefix: true },
            );
        } catch (error: any) {
            console.error("Failed to parse URL query string '%s'.", query, error);
            return null;
        }
    }

    static parseCancellationParams(query: string): SubscriptionCancellationParameters | null {
        const parsed = this.parseQuery(query);
        if (!parsed || !parsed[this.PARAM_SUBSCRIPTION_ID]) {
            return null;
        }

        let source = parsed[this.PARAM_SOURCE] || null;
        if (source !== null && !Object.values(SubscriptionUpdateSourceEnum).includes(source as SubscriptionUpdateSourceEnum)) {
            console.info("Ignoring source '%s' because it is invalid.", source);
            source = null;
        }

        return {
            subscription_id: Number(parsed[this.PARAM_SUBSCRIPTION_ID]),
            source: source as SubscriptionUpdateSourceEnum,
        };
    }

    static getSubscribeAndSaveUrl(): string {
        return this.makeUrl("/cart/subscriptions/");
    }

    static getPaymentMethodsUrl(): string {
        return this.makeUrl("/cart/payment-methods/");
    }

    static getCancelSubscriptionUrl(subscriptionId: number, source: SubscriptionUpdateSourceEnum): string {
        const baseUrl = this.makeUrl("/cancel-subscription/");

        const query = stringify({
            [this.PARAM_SUBSCRIPTION_ID]: subscriptionId,
            [this.PARAM_SOURCE]: source,
        });

        return `${baseUrl}?${query}`;
    }

    static getOrderUrl(orderId: number): string {
        return `${config.ALLIT_URL}/cart/account_history_info.php?order_id=${orderId}`;
    }

    static getFavoritesUrl(): string {
        return this.makeUrl("/cart/favorites/");
    }

    static getRecommendationsUrl(): string {
        return this.makeUrl("/cart/recommendations/");
    }

    static getProductUrl(product: Product): string | undefined {
        if (!product.slug) {
            return undefined;
        }

        const url = new URL(product.slug, config.ALLIT_URL);

        const parsed = this.parseQuery(url.search);
        if (!parsed) {
            return undefined;
        }

        const query = stringify({
            ...parsed,
            ...{ [product.is_apparel ? this.PARAM_VARIANT_ID : this.PARAM_PRODUCT_ID]: product.product_id },
        });

        return this.makeUrl(`${url.pathname.replace(/\/+$/u, "")}/?${query}${url.hash}`);
    }

    static makeUrl(allitUri: string): string {
        const url = `${config.ALLIT_URL}${allitUri}`;

        // If we're running in webpack-dev-server, use that URL instead of the allit one.
        return config.NODE_ENV === "production"
            ? url
            : this.ALLIT_URI_TO_WEBPACK_DEV_URI.get(allitUri) ?? url;
    }
}