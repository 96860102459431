import React, { ForwardedRef, HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import * as SS from "@techstack/styled-system";
import * as CSS from "csstype";
import { ifProp, prop } from "styled-tools";
import { FontWeightNames } from "../themes/interfaces";
import { textVariants, TypeStyles } from "./textVariants";
import NewWindowIndicator from "./NewWindowIndicator";

export interface TextProps
    extends HTMLAttributes<HTMLSpanElement>,
        SS.BorderProps,
        SS.ColorProps,
        SS.FlexboxProps,
        SS.LayoutProps,
        SS.GridProps,
        SS.PositionProps,
        SS.SpaceProps,
        SS.TypographyProps,
        Omit<SS.TypographyProps, "fontWeight"> {
    fontWeight?: FontWeightNames;
    textDecoration?: CSS.Property.TextDecoration<string>;
    textTransform?: CSS.Property.TextTransform;
    as?: any;
    target?: string;
    href?: string;
    rel?: string;
    typeStyle?: TypeStyles;
    htmlFor?: string;
    color?: any;
    noExternalLinkIcon?: boolean;
    ref?: any;
}

const Base = styled.span.attrs<TextProps>((p: TextProps) => ({
    textDecoration: p.href && !p.textDecoration ? "underline" : p.textDecoration,
}))<TextProps>`
    /* normalize browser styles */
    font-style: ${prop("fontStyle", "initial")};
    text-decoration: ${prop("textDecoration", "initial")};
    text-transform: ${prop("textTransform", "none")};
    -webkit-font-smoothing: ${(p) => (p.fontWeight === "light" ? "subpixel-antialiased" : "antialiased")};
    margin: 0;
    padding: 0;
    /* Set defaults here so they can be overriden by either typeStyles or props*/
    font-family: ${(p) => {
        if (p.fontFamily) {
            return p.theme.fonts[p.fontFamily as string];
        }
        return p.theme.fonts["primary"];
    }};

    ${SS.variant({
        key: "text.variants",
        prop: "typeStyle",
    })};

    /* These appear after typesStyles so we can override any aspect of a typeStyle */
    ${SS.border};
    ${SS.color};
    ${SS.flexbox};
    ${SS.grid};
    ${SS.layout};
    ${SS.position};
    ${SS.space};
    ${SS.typography};

    ${ifProp(
            "onClick",
            css`
                text-decoration: underline;
                cursor: pointer;
            `
    )};

    a {
        ${SS.color};
    }
`;

export const Text: React.FC<TextProps> = React.forwardRef((customProps, ref: ForwardedRef<any>) => {
    const props: TextProps = {
        display: "block",
        color: "text",
        bgColor: "transparent",
        border: "none",
        ref,
        ...customProps
    };

    if (props.as === "a" && props.target === "_blank") {
        return (
            <Base {...props}>
                {props.children}
                <NewWindowIndicator noExternalLinkIcon={props.noExternalLinkIcon}/>
            </Base>
        );
    } else {
        return <Base {...props} />;
    }
});

Text.displayName = "Text";

export default Text;

export const H1: React.FC<TextProps> = (props) => {
    return <Text as="h1" fontSize={[10, 10, 12, 14]} fontWeight="bold" lineHeight={2} mb={6} {...props} />;
};

export const H2: React.FC<TextProps> = (props) => {
    return <Text as="h2" fontSize={[8, 8, 8, 10, 12]} fontWeight="bold" lineHeight={2} mb={5} {...props} />;
};

export const H3: React.FC<TextProps> = (props) => {
    return <Text as="h3" fontSize={[6, 6, 7, 8, 9]} fontWeight="bold" lineHeight={3} mb={4} {...props} />;
};

export const H4: React.FC<TextProps> = (props) => {
    return (
        <Text
            as="h4"
            fontSize={[4, 4, 4, 4, 5]}
            fontWeight="heavy"
            textTransform="uppercase"
            lineHeight={3}
            mb={2}
            {...props}
        />
    );
};

export const H5: React.FC<TextProps> = (props) => {
    return (
        <Text
            as="h5"
            fontSize={2}
            fontWeight="heavy"
            textTransform="uppercase"
            lineHeight={2}
            mb={2}
            {...props}
        />
    );
};
