import React, { CSSProperties } from 'react';
import styled, { keyframes } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Box, { BoxProps } from '../box/Box';

interface Props extends BoxProps {
    showLoader?: boolean;
    bg?: string;
    bgShape?: 'circle' | 'dash' | 'none';
    size?: number;
    spinnerColor?: string;
    style?: CSSProperties;
}

const dash = keyframes`    
    10% {
        stroke-dashoffset: 250;
    }

    50% {
        stroke-dashoffset: 0;
    }

    69.99% {
        stroke-dashoffset: 0;
    }

    70% {
        stroke-dashoffset: 500;
    }

    90% {
        stroke-dashoffset: 250;
    }
`;

const Svg = styled.svg<{ bg: string; spinnerColor: string }>`
    -webkit-backface-visibility: hidden;

    .squiggleMask {
        stroke-dasharray: 250;
        stroke-dashoffset: 250;
        animation: ${dash} 2s cubic-bezier(0, 0, 0.15, 0.78) infinite;
    }

    .bg {
        fill: ${p => themeGet(`colors.${p.bg}`)(p)};
    }

    .squiggle {
        fill: ${p => themeGet(`colors.${p.spinnerColor}`)(p)};
    }
`;

function getBg(bgShape: Props['bgShape']) {
    switch (bgShape) {
        case 'circle':
            return <circle className="bg" cx="115" cy="115" r="115" />;
        case 'dash':
            return (
                <path
                    className="bg"
                    d="M212.37,60.37,62.22,60.29a14.56,14.56,0,0,0-12.35,7.43L5.79,143.28C1.35,151-1.07,169.76,20.72,169.76l150,.08a14.64,14.64,0,0,0,12.51-7.43l45.69-78.87C233.2,76.11,234.17,60.37,212.37,60.37Z"
                />
            );
        default:
            return null;
    }
}

const LogoProgress = ({ showLoader = false, size = 100, bgShape = "dash", bg = "black", spinnerColor = "white", ...rest }: Props) =>
    showLoader ? (
        <Box {...rest}>
            <Svg viewBox="0 0 231 231" width={size} height={size} bg={bg} spinnerColor={spinnerColor}>
                <defs>
                    <mask id="mask">
                        <polyline
                            className="squiggleMask"
                            points="47.46 134.83 75.46 93.83 102.46 134.83 129.46 93.83 155.96 134.33 183.46 93.83"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="20"
                        />
                    </mask>
                </defs>

                {getBg(bgShape)}

                <path
                    mask="url(#mask)"
                    d="M110.59,137.94c-1,1.53-3.07,4.44-7,4.44H98.89c-1.78,0-3.88-2-5.33-4.52L75.72,107a.54.54,0,0,0-1,0L56.83,137.94a8.87,8.87,0,0,1-7.51,4.44H42.94c-2.5-.09-3.47-2.27-2.18-4.44L66.67,93.05a8.84,8.84,0,0,1,7.43-4.44h4.52c2.1,0,3.64,1.78,5.17,4.44l17.76,30.84c.16.32.56.65,1,0l17.84-30.84a9,9,0,0,1,7.43-4.44h4.52c2.1,0,3.63,1.78,5.17,4.44l17.75,30.84c.17.32.57.65,1,0l17.93-30.84a8.64,8.64,0,0,1,7.34-4.44h6.38a2.78,2.78,0,0,1,2.42,4.44l-26,44.89c-1,1.53-3.06,4.44-7,4.44h-4.68c-1.77,0-3.87-2-5.33-4.52L129.4,107a.54.54,0,0,0-1,0Z"
                    className="squiggle"
                />
            </Svg>
        </Box>
    ) : null;

export default LogoProgress;
