import AbstractClient from "./AbstractClient";
import config from "../config";
import {ProductListResponse} from "../@types/interfaces/amazonPersonalize/ProductListResponse";
import ProductFactory from "../utils/ProductFactory";
import ProductResponse from "../@types/interfaces/product/ProductResponse";

export default class ProductClient extends AbstractClient {
    protected getBaseUrl(): string {
        return `${config.API_URL}/products`;
    }

    async listProductsSubscriptionEligible(): Promise<ProductListResponse> {
        const response = await this.axios.get("", {
            params: {
                filter: {
                    subscription_eligible: 1,
                    feed_exclude: 0,
                    product_status: 1,
                },
                sort: {
                    field: "name",
                    direction: "asc",
                },
                // We shouldn't exceed this.
                per_page: 1000,
                page: 1,
            },
            paramsSerializer: this.getUrlEncodedParamSerializer(),
        });

        response.data.data = response.data.data.map((product: ProductResponse) => ProductFactory.fromApiResponse(product));

        return response.data;
    }

    async listProductsInStockByIds(productIds: number[], page = 1, perPage = 10): Promise<ProductListResponse> {
        const response = await this.axios.get("", {
            params: {
                filter: {
                    id: productIds,
                    feed_exclude: 0,
                    is_in_stock: 1,
                    product_status: 1,
                },
                per_page: perPage,
                page,
            },
            paramsSerializer: this.getUrlEncodedParamSerializer(),
        });

        response.data.data = response.data.data.map((product: ProductResponse) => ProductFactory.fromApiResponse(product));

        return response.data;
    }
}