import React from "react";
import styled from "styled-components";
import { ifProp } from "styled-tools";
import Text from "../../text/Text";

interface Props {
    htmlFor?: string;
    doShrink?: boolean;
    children: any;
}

const StyledLabel = styled("div")<{ doShrink?: boolean }>`
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: ${ifProp("doShrink", "7px", "22px")};
    padding-left: 11px;
    transition: 0.2s;
    user-select: none;
`;

const FloatLabel = ({ doShrink, children, ...rest }: Props) => {
    return (
        <StyledLabel doShrink={doShrink}>
            <label {...rest}>
                <Text
                    fontSize={doShrink ? "14px" : 2}
                    color="grays.4"
                    style={{ transition: "0.2s" }}>
                    {children}
                </Text>
                {/* {required && <Text color="accent.0.3">{"\u2009*"}</Text>} */}
            </label>
        </StyledLabel>
    );
};

export default FloatLabel;
