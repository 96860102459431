import React from "react";
import Text from "@onnit-js/ui/components/text";

interface Props {
    productNames: string[];
}

export default function RemovedProductsText(props: Props) {
    const { productNames } = props;
    const words = productNames.length === 1 ? "item has" : "items have";

    return (
        <>
            <Text color="gameRed" fontSize={1} mb={2}>The following {words} been removed from your bag:&nbsp;</Text>
            {productNames.map((name) => (
                <Text key={name} color="gameRed" fontSize={1} mb={2}>&bull; {name}</Text>
            ))}
        </>
    );
}
