import React, { FC } from "react";
import Box from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import Switch, { SwitchSize } from "@onnit-js/ui/components/form/toggle/Switch";
import Cart from "../../../../../interfaces/cart/Cart";
import CartProduct from "../../../../../interfaces/cart/CartProduct";
import CartProductConfig from "../../../../../interfaces/cart/CartProductConfig";
import { useAppDispatch } from "../../../../../configureStore";
import { partiallyUpdateProducts } from "../../../../../slices/cartSlice";
import { setIsLoading } from "../../../../../slices/appSlice";
import SubscriptionDiscountCalculator from "../../../../../services/SubscriptionDiscountCalculator";

const hasSubscriptionProduct = (products: CartProduct[], withUnselectedInterval = false): boolean => products.some((cp) => (
    cp.product.isEligibleForSubscription
    && (!withUnselectedInterval || cp.subscription_interval === null)
));

const canSetDefaultInterval = (cp: CartProduct): boolean => (
    cp.subscription_interval === null
    && cp.product.isEligibleForSubscription
    && cp.product.defaultSubscriptionIntervalDays !== null
);

const allIntervalsSelected = (products: CartProduct[]): boolean => products.every((cp) => !canSetDefaultInterval(cp));

const getUpdatePayload = (products: CartProduct[], isEnabled: boolean): CartProductConfig[] => (
    products.reduce<CartProductConfig[]>((acc, cp) => {
        const payload: CartProductConfig = {
            product_id: cp.product.id,
            quantity: cp.quantity,
        };
        if (isEnabled && canSetDefaultInterval(cp)) {
            payload.subscription_interval = cp.product.defaultSubscriptionIntervalDays;
            acc.push(payload);
        } else if (!isEnabled && cp.product.isEligibleForSubscription) {
            payload.subscription_interval = null;
            acc.push(payload);
        }
        return acc;
    }, [])
);

interface Props {
    cart: Cart;
    canDisable: boolean;
}

export const calculator = new SubscriptionDiscountCalculator();

const SubscriptionSwitchBanner: FC<Props> = ({ cart, canDisable = true }) => {
    const hasEligibleProduct = hasSubscriptionProduct(cart.products);
    const isEnabled = allIntervalsSelected(cart.products);
    const isVisible = hasEligibleProduct && (!isEnabled || canDisable);
    const discount = calculator.getDiscountSummary(cart);
    const dispatch = useAppDispatch();

    const onChange = async () => {
        try {
            dispatch(setIsLoading(true));
            const configs = getUpdatePayload(cart.products, !isEnabled);
            await dispatch(partiallyUpdateProducts(configs, cart.cart_uuid));
        } catch (error) {
            console.error("Failed to handle change of subscription toggle banner.", error);
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    return isVisible ? (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            backgroundColor="earthGreens.0"
            borderRadius="4px"
            p={1}
            mt={2}
        >
            <Switch enabled={isEnabled} onChange={onChange} size={SwitchSize.LARGE} />
            <Text as="p" textTransform="uppercase" fontSize={[2, 2, 3]} ml={2} color="earthGreens.6" fontWeight="bold">
                Autoship & Save {discount.differ && "Up To "}{discount.highestPercentage}%
            </Text>
        </Box>
    ) : null;
};

export default SubscriptionSwitchBanner;
