import ProductData from "../../@types/interfaces/google-tag-manager/ProductData";
import Product from "../../@types/interfaces/product/Product";
import {ListNameEnum} from "../../@types/enums/ListNameEnum";

export default class ProductDataFactory {
    static fromProduct = (product: Product, list: ListNameEnum, position: number): ProductData => ({
        // UA parameters
        id: product.product_group_id,
        name: product.product_group,
        variant: product.product_name,
        category: product.category,
        brand: product.brand ?? undefined,

        // GA4 parameters
        item_id: product.product_group_id,
        item_name: product.product_group,
        item_brand: product.brand ?? undefined,
        item_category: product.category,
        item_variant: product.product_name,
        price: product.price,

        // Used by other
        productId: product.product_id,
        imageUrl: product.authority_image_url,
        list,
        position,
    });
}
