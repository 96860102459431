import React from "react";
import styled from "styled-components";
import LogoProgress from "./LogoProgress";
import Box, { BoxProps } from "../box/Box";

const Overlay = styled(Box)<BoxProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: 100%;
    user-select: none;
    z-index: 10000000;
`;

const OverlayProgress = ({ bg = "rgba(255, 255, 255, 0.6)", width = "100%", position = "fixed", ...rest }: BoxProps) => {
    return (
        <Overlay
            bg={bg}
            width={width}
            position={position}
            {...rest}
            onClick={(e) => {
                if (e.stopPropagation) {
                    e.stopPropagation();
                }
                if (e.nativeEvent && e.nativeEvent.stopImmediatePropagation) {
                    e.nativeEvent.stopImmediatePropagation();
                }
            }}>
            <LogoProgress showLoader={true} />
        </Overlay>
    );
};

export default OverlayProgress;
