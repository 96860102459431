import { ThemeInterface } from "./interfaces";
import { base } from "./base";
import cloneDeep from "lodash/cloneDeep";

const light = cloneDeep(base);

light.colors["text"] = light.colors.black;

export default <ThemeInterface>{
    ...light,
    name: "light",
    backgroundColor: light.colors.white,
    component: {
        button: {
            bg: light.colors.black,
            color: light.colors.white
        },
        formfield: {
            initial: {
                borderColor: light.colors.grays[1],
                helperTextColor: light.colors.grays[4]
            },
            active: {
                borderColor: light.colors.black,
                helperTextColor: light.colors.grays[4]
            },
            error: {
                borderColor: light.colors.gameRed,
                helperTextColor: light.colors.gameRed
            },
            valid: {
                borderColor: "#4a8203",
                helperTextColor: "#4a8203"
            },
            disabled: {
                borderColor: light.colors.grays[2],
                helperTextColor: light.colors.grays[2]
            },
            readOnly: {
                borderColor: light.colors.grays[2],
                helperTextColor: light.colors.grays[2]
            }
        },
        toggle: {
            initial: {
                borderColor: light.colors.grays[1]
            },
            active: {
                borderColor: light.colors.sunwashGold
            }
        }
    }
};
