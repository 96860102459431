import * as googleTagManager from "./listeners/googleTagManager";
import CartProductsAddedEvent from "./events/CartProductsAddedEvent";
import CartProductRemovedEvent from "./events/CartProductRemovedEvent";
import CartViewedEvent from "./events/CartViewedEvent";
import PageInitializedEvent from "./events/PageInitializedEvent";
import OrderCreatedEvent from "./events/OrderCreatedEvent";
import ProductImpressionEvent from "./events/ProductImpressionEvent";
import PageSubmittedEvent from "./events/PageSubmittedEvent";
import UserSessionUpdatedEvent from "./events/UserSessionUpdatedEvent";
import PasswordSetEvent from "./events/PasswordSetEvent";
import CartCouponEnteredEvent from "./events/CartCouponEnteredEvent";
import CartCouponAppliedEvent from "./events/CartCouponAppliedEvent";
import CartCouponRemovedEvent from "./events/CartCouponRemovedEvent";
import CartCouponDeniedEvent from "./events/CartCouponDeniedEvent";
import SurveyCompletedEvent from "./events/SurveyCompletedEvent";

type Listener = (event?: any) => void;

interface ListenerMap {
    readonly [eventName: string]: Listener[];
}

//-------------------------------------------------------------------------------------------
// NOTICE: All third party tracking code should now reside in Google Tag Manager exclusively.
//-------------------------------------------------------------------------------------------

const EventListenerMap: ListenerMap = Object.freeze({
    [UserSessionUpdatedEvent.eventName]: [
        googleTagManager.trackUserSession,
    ],
    [ProductImpressionEvent.eventName]: [
        googleTagManager.trackProductImpression,
    ],
    [CartCouponEnteredEvent.eventName]: [
        googleTagManager.trackCouponEntered,
    ],
    [CartCouponAppliedEvent.eventName]: [
        googleTagManager.trackCouponApplied,
    ],
    [CartCouponDeniedEvent.eventName]: [
        googleTagManager.trackCouponDenied,
    ],
    [CartCouponRemovedEvent.eventName]: [
        googleTagManager.trackCouponRemoved,
    ],
    [CartProductsAddedEvent.eventName]: [
        googleTagManager.trackProductsAdded,
    ],
    [CartProductRemovedEvent.eventName]: [
        googleTagManager.trackProductRemoved,
    ],
    [CartViewedEvent.eventName]: [
        googleTagManager.trackCartViewed,
    ],
    [PageInitializedEvent.eventName]: [
        googleTagManager.trackCheckoutStep,
    ],
    [PageSubmittedEvent.eventName]: [
        googleTagManager.trackCheckoutOption,
    ],
    [OrderCreatedEvent.eventName]: [
        googleTagManager.trackPurchase,
    ],
    [PasswordSetEvent.eventName]: [
        googleTagManager.trackPasswordSet,
    ],
    [SurveyCompletedEvent.eventName]: [
        googleTagManager.trackSurveyCompleted,
    ]
});

export default EventListenerMap;
