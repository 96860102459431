import Text, { TextProps } from "../text/Text";
import NumberUtil from "../../utils/NumberUtil";
import React from "react";
import Product from "../../@types/interfaces/product/Product";

interface RecommendationItemDetailsProps extends TextProps {
    item: Product;
}

const RecommendationItemPrice: React.FC<React.PropsWithChildren<RecommendationItemDetailsProps>> = ({ item, ...rest }) => {
    const { msrp, price } = item;
    const isOnSale = msrp > price;

    return (
        <>
            {
                isOnSale && (
                    <>
                        <p className="sr-only">Normally,</p>
                        <Text
                            display="inline-block"
                            textDecoration="line-through"
                            color="grays.4"
                            mr={2}
                            {...rest}
                        >
                            {NumberUtil.formatMoney(msrp)}
                        </Text>
                        <p className="sr-only">Your price today,</p>
                    </>
                )
            }
            <Text display="inline-block" fontWeight="bold" {...rest}>
                {NumberUtil.formatMoney(price)}
            </Text>
        </>
    );
}

export default RecommendationItemPrice;