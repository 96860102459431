import React from "react";
import Text, { TextProps } from "@onnit-js/ui/components/text/Text";
import NumberUtil from "../../../../utils/NumberUtil";

export enum PriceThemeEnum {
    LIGHT,
    DARK,
}

interface Props extends TextProps {
    basePrice: number;
    actualPrice: number;
    theme?: PriceThemeEnum;
    fontSize?: string | number | Array<string | number>;
    currency?: string | null;
    basePriceColor?: string;
}

const PriceLabel: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const { basePrice, actualPrice, theme = PriceThemeEnum.DARK, fontSize, basePriceColor = "grays.4" } = props;
    const isActualPriceFree = actualPrice === 0;
    const currencyTextColor = theme === PriceThemeEnum.LIGHT ? "white" : "black";

    return (
        <div>
            {basePrice > actualPrice && (
                <>
                    <span className="sr-only">Normally,</span>
                    <Text
                        display="inline-block"
                        fontSize={fontSize}
                        textDecoration="line-through"
                        color={basePriceColor}
                        mr={2}
                    >
                        {NumberUtil.formatMoney(basePrice)}
                    </Text>
                    <span className="sr-only">Your price today,</span>
                </>
            )}
            <Text
                display="inline-block"
                fontSize={fontSize}
                fontWeight={isActualPriceFree ? "bold" : "regular"}
                color={currencyTextColor}
            >
                { props.currency && (
                    <Text textTransform="uppercase" display="inline-block" pr={3} fontWeight="regular" color={isActualPriceFree ? basePriceColor : currencyTextColor} verticalAlign="middle" fontSize="11px" textDecoration={isActualPriceFree ? "line-through" : ""}>
                        {props.currency}{" "}
                    </Text>
                )}
                {isActualPriceFree ? "FREE" : NumberUtil.formatMoney(actualPrice)}
            </Text>
        </div>
    );
};

export default PriceLabel;
