import React from "react";
import { TextProps } from "./Text";
import styled from "styled-components";
import { MdOpenInNew } from "react-icons/md";
import Icon from "../icon/Icon";

interface Props extends TextProps {
    noExternalLinkIcon?: boolean
    width?: number | undefined;
    height?: number | undefined;
    title?: string | undefined;
}

const NewExternalLinkIconSvg = styled.span.attrs((p: Props)=> ({
}))<Props>`
    svg {
        display: inline-block;
        vertical-align: middle;
        margin-left: 3px;
        fill: currentColor;
    }
`;

const NewWindowIndicator: React.FC<React.PropsWithChildren<Props>> = ({
                                                 noExternalLinkIcon = false,
                                                 width = 14,
                                                 height = 14,
                                                 title = "Opens in new window.",
                                                 ...rest
                                             }) => {
    return (
        noExternalLinkIcon ?
            (
                <span className="sr-only">{title}</span>
            )
            : (

                <NewExternalLinkIconSvg {...rest}>
                    <Icon width={width} height={height} title={title} icon={MdOpenInNew} />
                </NewExternalLinkIconSvg>
            )
    );
};

export default NewWindowIndicator;
