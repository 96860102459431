import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartProductConfig } from "@onnit-js/ui/@types/interfaces/CartProductConfig";
import { difference, uniq } from "lodash";
import { PostUpsellStateInterface } from "../interfaces/PostUpsell/PostUpsellInterface";
import Cart from "../interfaces/cart/Cart";
import CreateOrderResult from "../interfaces/order/CreateOrderResult";
import CartProductClient from "../clients/cart/CartProductClient";
import CartOrderClient from "../clients/cart/CartOrderClient";
import ThunkAction from "../interfaces/ThunkAction";
import CreateOrderConfig from "../interfaces/order/CreateOrderConfig";

const initialState: PostUpsellStateInterface = {
    cart: null,
    order: null,
    paymentMethod: null,
    addedUpsellIds: [],
};

const postUpsellSlice = createSlice({
    name: "postUpsell",
    initialState,
    reducers: {
        productsAdded(state, action: PayloadAction<{ cart: Cart, upsellIds: string[] }>) {
            return {
                ...state,
                paymentMethod: action.payload.cart.payment_method ?? state?.paymentMethod, // We only get the payment method on initial cart creation.
                cart: action.payload.cart,
                addedUpsellIds: uniq([
                    ...state.addedUpsellIds,
                    ...action.payload.upsellIds,
                ]),
            };
        },
        productsRemoved(state, action: PayloadAction<{ cart: Cart, upsellIds: string[] }>) {
            return {
                ...state,
                cart: action.payload.cart,
                addedUpsellIds: difference(state.addedUpsellIds, action.payload.upsellIds),
            };
        },
        orderPlaced(state, action: PayloadAction<CreateOrderResult>) {
            return {
                ...state,
                order: action.payload,
            };
        },
    }
});

export const {
    productsAdded,
    productsRemoved,
    orderPlaced
} = postUpsellSlice.actions;

export default postUpsellSlice;

// ------------------------- [ Thunks ] -------------------------

const cartProductClient = new CartProductClient();
const orderClient = new CartOrderClient();

export const addProducts = (
    cartProductConfigs: CartProductConfig[],
    upsellIds: string[],
    cartUuid?: string | null,
    cartInitializer?: string | null,
): ThunkAction<Promise<Cart>> => (
    async (dispatch) => {
        const uuid: typeof cartUuid = cartUuid;
        let initializer: typeof cartInitializer = cartInitializer;
        if (uuid) {
            initializer = null;
        }
        if (!uuid && !initializer) {
            throw new Error("Cart UUID or Cart Initializer must be provided.");
        }

        const response = await cartProductClient.addProducts({
            productConfigs: cartProductConfigs,
            cartUuid: uuid,
            cartInitializer: initializer,
            doCalculateTax: true,
            trackUri: window.location.pathname,
            clientCountryCode: window.onnit_context?.client?.country_code,
        });
        dispatch(productsAdded({ cart: response.data, upsellIds }));

        return response.data;
    }
);

export const removeProducts = (productIds: number[], upsellIds: string[], cartUuid: string): ThunkAction<Promise<Cart>> => (
    async (dispatch) => {
        const configs = productIds.map<CartProductConfig>((id) => ({
            product_id: id,
            quantity: 0,
        }));
        const response = await cartProductClient.partiallyUpdateProducts(configs, cartUuid, true);
        dispatch(productsRemoved({ cart: response.data, upsellIds }));

        return response.data;
    }
);

export const createOrder = (cart: Cart, config: CreateOrderConfig): ThunkAction<Promise<CreateOrderResult>> => (
    async (dispatch) => {
        const response = await orderClient.chargeAndCreateOrder(cart.cart_uuid, config);
        const order = response.data;

        dispatch(orderPlaced(order));
        // NOTE: Not dispatching the OrderCreatedEvent since we don't
        // want to notify any downstream services of the post upsell order
        return order;
    }
);
