import {MouseEvent} from "react";
import Product from "../../@types/interfaces/product/Product";
import ProductDataFactory from "./ProductDataFactory";
import {ListNameEnum} from "../../@types/enums/ListNameEnum";

export default class GoogleTagManagerUtil {
    static productClick(event: MouseEvent<HTMLAnchorElement>, product: Product, list: ListNameEnum, index: number): void {
        if (!window.ONNIT?.googleTagManager) {
            console.error("Google Tag Manager: Cannot send product click because `window.ONNIT.googleTagManager` is undefined.");
            return;
        }

        // Prevent navigation and delegate it to GTM if it's loaded, as it could be blocked by a browser extension.
        // In either case, we still send the click payload in the edge case that the SDK is still loading.
        if (window.ONNIT.googleTagManager.isSdkLoaded()) {
            event.preventDefault();
        }

        const productData = ProductDataFactory.fromProduct(product, list, index + 1);
        window.ONNIT.googleTagManager.productClick([productData], list, event.currentTarget.href);
    }

    static productImpression(products: Product[], list: ListNameEnum): void {
        window.ONNIT?.googleTagManager?.productImpression(
            products.map((product, index) => (
                ProductDataFactory.fromProduct(product, list, index + 1)
            )),
        );
    }
}
