import React from "react";
import NumberField from "@onnit-js/ui/components/form/numberfield/NumberField";
import Text from "@onnit-js/ui/components/text/Text";
import Box from "@onnit-js/ui/components/box/Box";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { MdClose } from "react-icons/md";
import PriceLabel, { PriceThemeEnum } from "../total/PriceLabel";
import Cart from "../../../../interfaces/cart/Cart";
import CartProductInterface from "../../../../interfaces/cart/CartProduct";
import ProductImageBox from "./ProductImage";
import ProductUtil from "../../../../utils/ProductUtil";
import DiscountBubble from "./DiscountBubble";
import SubscriptionDetailsText from "./subscription/SubscriptionDetailsText";
import PromoDiscountLabel from "./PromoDiscountLabel";
import CartPromoDiscountConfig from "../../../../interfaces/cart/CartPromoDiscountConfig";
import SubscriptionSwitchProduct from "./subscription/SubscriptionSwitchProduct";

interface Props {
    cart: Cart;
    cartProduct: CartProductInterface;
    onQuantityChange: (newQuantity: number) => void;
    onSubIntervalChange: (newInterval: number) => void;
    onRemove: () => void;
    promoDiscount?: CartPromoDiscountConfig;
}

const CartProductMutable = ({ cartProduct, onQuantityChange, onSubIntervalChange, onRemove, promoDiscount }: Props) => {
    const { product } = cartProduct;
    const productUrl = ProductUtil.makeProductUrl(product.slug);
    const quantityMax = cartProduct.quantity_max;

    return (
        <Box py={3} borderBottom="1px solid" borderColor="grays.1">

            <Box display="flex">
                <ProductImageBox
                    imageUrl={product.authorityImageUrl}
                    productUrl={productUrl}
                    alt={product.name}
                />
                <Box flex="1" display="flex" flexDirection="column" pt={3}>
                    <Box display="flex" mb={1} alignItems="flex-start">
                        <Text
                            as="p"
                            display="block"
                            fontWeight="heavy"
                            fontSize={[2, "18px"]}
                            href={productUrl}
                            flex="1"
                            pr={1}
                            textDecoration="none"
                        >
                            {product.name}
                        </Text>
                        <Icon
                            p="1px 4px 4px 4px"
                            icon={MdClose}
                            color="grays.5"
                            onClick={() => onRemove?.()}
                            ariaLabel="Remove Product"
                        />
                    </Box>
                    <Box my={1}>
                        <PriceLabel
                            basePrice={cartProduct.totals.msrp}
                            actualPrice={cartProduct.totals.adjusted_price}
                            theme={PriceThemeEnum.DARK}
                        />
                        <DiscountBubble
                            basePrice={cartProduct.totals.msrp}
                            actualPrice={cartProduct.totals.adjusted_price}
                            mt={2}
                            mr={1}
                        />
                        {promoDiscount && (
                            <PromoDiscountLabel
                                mt={2}
                                promoDiscount={promoDiscount}
                                productId={cartProduct.product.id}
                            />
                        )}
                    </Box>
                    <NumberField
                        ml="auto"
                        min={0}
                        max={quantityMax}
                        value={cartProduct.quantity}
                        onChange={(event) => onQuantityChange?.(parseInt(event.target.value))}
                    />
                </Box>
            </Box>
            <Box ml={2}>
                <SubscriptionSwitchProduct
                    cartProduct={cartProduct}
                    promoDiscount={promoDiscount}
                    onChange={(interval) => onSubIntervalChange(interval ?? 0)}
                />
                <SubscriptionDetailsText details={cartProduct.subscription_details} />
            </Box>
        </Box>
    );
};

export default CartProductMutable;
